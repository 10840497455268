import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link, Events } from 'react-scroll';

const JumperMenu = (props) => {

  const { items } = props;

  const [ anchorEl, setAnchorEl ] = useState(null);

  const translations = useSelector((state) => state.translations);
  const currentLang = useSelector((state) => state.navigation.currentLang);

  const label = props.label || translations['jump']?.[currentLang] || 'Jump to';

  useEffect(() => {
    Events.scrollEvent.register('begin', () => {
      closeMenu();
    });

    return () => Events.scrollEvent.remove('begin');
  }, []);

  const openMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => setAnchorEl(null);

  return (
    <div>
      <Button variant="contained"
        onClick={openMenu}>
          {label}
        <ArrowDropDown />
      </Button>
      <Menu 
        className="jumper-menu"
        anchorEl={anchorEl} 
        open={Boolean(anchorEl)} 
        onClose={closeMenu}>
        {
          items.map((item) => (
            <Link to={item.id} key={item.id} smooth={true} duration={700}>
              <MenuItem>
                {item.label}
              </MenuItem>
            </Link>
          ))
        }
      </Menu>
    </div>
  );
};

export default JumperMenu;