import React, { useEffect, useState } from "react";

import Container from "@material-ui/core/Container";
import T from "../common/Translation"

import { connect } from "react-redux";
import {
  openEmailModal,
  closeEmailModal,
} from "../../redux/actions";

const mapStateToProps = state => {
  return {
    showEmailModal: state.adminTools.showEmailModal,
    translations: state.translations,
    lang: state.navigation.currentLang,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openEmailModal: () => {
      dispatch(openEmailModal());
    },
    closeEmailModal: () => {
      dispatch(closeEmailModal());
    }
  };
};

const EmailSubscriptionModal = ({ openEmailModal, closeEmailModal, showEmailModal, setEmailModalReady, translations, lang }) => {
  const formId = translations["modal_ctct_form_id"] ? translations["modal_ctct_form_id"][lang] : '137dd4d7-e832-48fc-bfea-0a493a1957df';
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    const optOut = window.localStorage.getItem('ctct-subscription-opt-out')
    if (!optOut) {
      setTimeout(openEmailModal, 3000)
    }
  }, [openEmailModal])

  useEffect(() => {
    if (showEmailModal) {
      document.body.style.overflow = "hidden"
      const successMessage = document.querySelector("#email-subscription-modal .ctct-form-success")
      console.log({successMessage})
      const observer = new MutationObserver(function(mutationsList, observer) {
        console.log(mutationsList)
        setSubmitted(true)
      });

      // call 'observe' on that MutationObserver instance,
      // passing it the element to observe, and the options object
      observer.observe(successMessage, {attributes: true});
    } else {
      document.body.style.overflow = "auto"
    }
  }, [showEmailModal])

  const handleNoThanks = () => {
    window.localStorage.setItem('ctct-subscription-opt-out', true)
    closeEmailModal()
  }

  return (
    <div id="email-subscription-modal" className={showEmailModal ? 'open' : 'closed'}>
      <Container maxWidth="sm">
        <div className="bg-light email-subscription">
          <div className="ctct-inline-form" data-form-id={formId} data-form-lang={lang}></div>
          <div className="text-center footer">
            <button onClick={handleNoThanks} className={submitted ? "flr-btn" : ""}>
              {submitted ? (
              <T id="close_and_continue" />
              ) : (
              <T id="no_thanks" />
              )}
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailSubscriptionModal);
