import React from "react";
import { connect } from "react-redux";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import T from "../common/Translation"
import { NAWL_URLS } from "../../utils/constants";

const mapStateToProps = state => {
  return {
    translations: state.translations,
    lang: state.navigation.currentLang,
  };
};


const InlineFooter = ({ translations, lang }) => {
  const formId = translations["inline_ctct_form_id"] ? translations["inline_ctct_form_id"][lang] : '5d95200d-afa8-479d-8d5b-bf53a374d3c0';
  const nawl = NAWL_URLS[lang] || NAWL_URLS['en'];
  return (
    <section id="nawl-footer" className="highlight">
      <Container maxWidth="md">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <h2 className="footer-header">
              <T id="nawl_header" />
            </h2>
            <p>
              <T id="nawl_description" />
            </p>
            <a href={nawl} target="_blank" rel="noopener noreferrer">
              <T id="nawl_link_text" />
            </a>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="bg-light email-subscription">
              <div className="ctct-inline-form" data-form-id={formId} data-form-lang={lang}></div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default connect(mapStateToProps, null)(InlineFooter);
