import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"

const config = {
    apiKey: "AIzaSyCKrbZ-ZfH55PLDX-ykIwYrLpU_IL6drww",
    authDomain: "feministlawreform101.firebaseapp.com",
    projectId: "feministlawreform101",
    storageBucket: "feministlawreform101.appspot.com",
    messagingSenderId: "604487297323",
    appId: "1:604487297323:web:ee4ad989cd586fcbb6bd6e"
  }

firebase.initializeApp(config);
const firestore = firebase.firestore()
firestore.settings({ timestampsInSnapshots: true })

export default firebase;
export { firestore };
